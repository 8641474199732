<template>
  <div class="container initial-step__container">
    <div class="section-header">
      <h2 class="section-header__title">
        Choose your cover letter by desired job industry
      </h2>

      <p class="section-header__text">Select the one that best describes you</p>
    </div>

    <CreationOptions
      :creationOptions="jobIndustryOptions"
      :optionType="optionType"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import CreationOptions from "@/components/creation/CreationOptions";

export default {
  name: "StepInitJobIndustry",
  components: {
    CreationOptions,
  },
  data() {
    return {
      jobIndustryOptions: [],
      optionType: "group",
    };
  },
  async created() {
    await this.$store.dispatch("loader/pending");
    try {
      this.jobIndustryOptions = await this.$store.dispatch(
        "coverLetterCreation/getIndustryOptions"
      );
      await this.$store.dispatch("loader/done");
    } catch (err) {
      console.error(err);
    }
  },
};
</script>

<style lang="scss" scoped>
.initial-step__container {
  padding-top: 40px;
  padding-bottom: 40px;
}
</style>
