<template>
  <section class="initial-step">
    <transition name="fade" mode="out-in">
      <component
        :is="currentStep"
        :industry="coverLetter.group"
        @submit="setCoverLetterInitData"
        @prev="prevStep"
        @finalize="createCoverLetter"
      ></component>
    </transition>
  </section>
</template>

<script>
import StepInitJobIndustry from "./components/StepInitJobIndustry";
import StepInitJobTitle from "./components/StepInitJobTitle";
import StepInitLoader from "../../resume-creation/step-init/components/StepInitLoader";
import { CREATING_COVER_LETTER_NAMES } from "@/helpers/rout-constants";
import { mapGetters } from "vuex";
import AppToast from "@/helpers/toast-methods";
import { mixpanelMixin } from "@/mixins/MixPanelMixin";

const STEP_INIT_STEPS = [
  {
    name: "StepInitJobIndustry",
  },
  {
    name: "StepInitJobTitle",
  },
  {
    name: "StepInitLoader",
  },
];

export default {
  name: "StepInitialLetterCreation",
  mixins: [mixpanelMixin],
  components: {
    StepInitJobIndustry,
    StepInitJobTitle,
    StepInitLoader,
  },
  data() {
    return {
      STEP_INIT_STEPS,
      currentStep: STEP_INIT_STEPS[0].name,
      coverLetter: {
        template_id: 1,
        group: "",
        name: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
      user: "user/user",
    }),
  },
  created() {
    if (this.$route.params?.templateId === undefined) {
      AppToast.toastError("No cover letter template selected...");
      this.$router.push("/");

      return;
    }

    this.mixpanelTrack("V2-INITIALLETTER");

    this.coverLetter.template_id = this.$route.params.templateId;
  },
  methods: {
    nextStep() {
      const currentStepIndex = STEP_INIT_STEPS.findIndex(
        (stepName) => stepName.name === this.currentStep
      );

      this.mixpanelTrack(
        "V2-INITIALLETTER-" + STEP_INIT_STEPS[currentStepIndex + 1].name
      );

      this.currentStep = STEP_INIT_STEPS[currentStepIndex + 1].name;
    },

    prevStep() {
      this.currentStep = STEP_INIT_STEPS[this.getCurrentStep() - 1].name;
    },

    getCurrentStep() {
      return STEP_INIT_STEPS.findIndex(
        (stepName) => stepName.name === this.currentStep
      );
    },

    setCoverLetterInitData(dataToSave) {
      this.coverLetter[dataToSave.key] = dataToSave.value;

      this.nextStep();
    },
    async createCoverLetter() {
      try {
        let coverLetter = { ...this.coverLetter };

        if (this.user) {
          Object.assign(coverLetter, this.user);
          delete this.coverLetter.password_exists;
        }
        await this.$store.dispatch(
          "coverLetterCreation/createCoverLetter",
          coverLetter
        );
        await this.$router.push({ name: CREATING_COVER_LETTER_NAMES.STEP_1 });
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
section.initial-step {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (min-width: map_get($grid-breakpoints, lg)) {
    padding: 0 250px;
  }
}

::v-deep .initial-step__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

::v-deep .section-header {
  margin-top: 40px;
  margin-bottom: 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: map_get($grid-breakpoints, lg)) {
    margin-bottom: 40px;
  }

  h2 {
    max-width: 670px;
  }

  &__title {
    font-size: 32px;

    @media (min-width: map_get($grid-breakpoints, lg)) {
      font-size: 40px;
    }

    span {
      color: $primary;
    }
  }

  &__text {
    font-size: 16px;
    color: $light-gray;
    margin-top: 16px;

    @media (min-width: map_get($grid-breakpoints, lg)) {
      font-size: 18px;
    }
  }
}

::v-deep .back-btn {
  padding: 20px;
  margin-top: 20px;
  color: $body-color;
  font-size: 14px;

  &:hover {
    color: $primary;
  }
}
</style>
