<template>
  <div class="job-options">
    <div class="row justify-content-center">
      <div
        class="job-options__item col-sm-auto col-md-4 col-lg-auto"
        v-for="(option, idx) in creationOptions"
        :key="idx"
        @click="chooseOption(option)"
      >
        <h3 class="job-options__title">{{ option }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreationOptions",
  props: {
    creationOptions: {
      type: Array,
    },
    optionType: {
      type: String,
    },
    isEnablePrevStep: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    chooseOption(option) {
      this.$emit("submit", { key: this.optionType, value: option });
    },
  },
};
</script>

<style lang="scss" scoped>
.job-options {
  max-width: map_get($container-max-widths, lg);

  &__item {
    margin: 0 12px 24px 12px;
    padding: 24px 32px;
    background-color: #fff;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $primary;

      .job-options__title {
        color: #fff;
      }
    }
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    color: $body-color;
    transition: color 0.3s ease;
  }
}
</style>
