<template>
  <div class="container initial-step__container">
    <div class="section-header">
      <h2 class="section-header__title">Please select your job title</h2>

      <p class="section-header__text">Select the one that best describes you</p>
    </div>

    <CreationOptions
      v-on="$listeners"
      isEnablePrevStep
      :creationOptions="jobTitleOptions"
      :optionType="optionType"
    />
  </div>
</template>

<script>
import CreationOptions from "@/components/creation/CreationOptions";

export default {
  name: "StepInitJobTitle",
  components: {
    CreationOptions,
  },
  props: {
    industry: {
      type: String,
    },
  },
  data() {
    return {
      jobTitleOptions: [],
      optionType: "name",
    };
  },
  async created() {
    await this.$store.dispatch("loader/pending");
    try {
      this.jobTitleOptions = await this.$store.dispatch(
        "coverLetterCreation/getJobOptions",
        this.industry
      );
      await this.$store.dispatch("loader/done");
    } catch (err) {
      console.error(err);
    }
  },
};
</script>

<style scoped></style>
