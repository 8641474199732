<template>
  <div class="start-step" :class="{ 'start-step--center': isResumeLoader }">
    <div class="step-loader-wrap">
      <div class="step-loader-track">
        <div
          class="step-loader"
          :class="{ 'step-loader--active': isActiveLoader }"
        >
          <div class="step-loader__item">
            <AppIcon componentName="CheckIcon" />
            Loading Resume Designs
          </div>
          <div class="step-loader__item">
            <AppIcon componentName="CheckIcon" />
            Loading Tips & Samples
          </div>
          <div class="step-loader__item">
            <AppIcon componentName="CheckIcon" />
            Loading Fonts
          </div>
          <div class="step-loader__item">
            <AppIcon componentName="CheckIcon" />
            Loading Resume Tools
          </div>
          <div class="step-loader__item">
            <AppIcon componentName="CheckIcon" />
            Ready
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepInitExperience",

  data() {
    return {
      isActiveLoader: false,
    };
  },
  props: {
    isResumeLoader: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    setActiveLoader() {
      this.isActiveLoader = true;
      setTimeout(() => {
        this.$emit("finalize");
      }, 2500);
    },
  },

  mounted() {
    document.body.scrollTop = 0; // for safari
    document.documentElement.scrollTop = 0; // for chrome, firefox, IE
    document.body.style.overflow = "hidden";
    this.setActiveLoader();
  },

  beforeDestroy() {
    document.body.style.overflow = "auto";
  },
};
</script>

<style lang="scss" scoped>
.start-step {
  @media (min-width: map_get($grid-breakpoints, lg)) {
    margin-top: -56px;
  }
}
.start-step--center {
  position: absolute;
  width: 100%;
  height: calc(100vh - 120px);
}
.step-loader-wrap {
  display: flex;
  justify-content: center;

  @media (max-width: map_get($grid-breakpoints, md)) {
    align-items: center;
    flex: 1;
    padding: 0 30px;
  }
}

.step-loader-track {
  height: 180px;
  width: 350px;
  max-width: 100%;
  position: relative;
  overflow: hidden;
  margin-bottom: 100px;

  @media (max-width: map_get($grid-breakpoints, md)) {
    width: 300px;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      180deg,
      #fafafb 0%,
      rgba(250, 250, 251, 0) 50.52%,
      #fafafb 100%
    );
    z-index: 2;
  }
}

.step-loader {
  transform: translateY(60px);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0 20px;

  &--active {
    animation: scroll 5s forwards;
  }

  &__item {
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    line-height: 24px;
    padding: 14px 0;

    @media (max-width: map_get($grid-breakpoints, md)) {
      font-size: 16px;
    }

    svg {
      width: 32px;
      height: 32px;
      fill: $primary;
      margin-right: 16px;
    }
  }
}

@keyframes scroll {
  0% {
    transform: translateY(60px);
  }
  25% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-60px);
  }
  75% {
    transform: translateY(-120px);
  }
  100% {
    transform: translateY(-180px);
  }
}
</style>
